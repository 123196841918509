import { useQuery } from '@apollo/client';
import { Paging } from '@appvantageasia/afc-ui';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { exportEventsApplication } from '../../../../../actions';
import { FootBar, FootBarButton, ScrollContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationPhase, EventExternalSite } from '../../../../../schema';
import { getCurrentZone, getZoneId } from '../../../../../selectors';
import { useCompanyFormatting } from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { getSortingOrderLabel } from '../../../Appointments/lists/AppointmentList';
import { getTransactionId } from '../../../Workflow/Finance/lists/ApplicationList';
import { getEventApplicationsByZoneId } from '../Edition.graphql';
import Download from './Download';
import { withEventApplications } from './EventApplicationsContext';
import { useFormContext } from './context';
import { useReducerActions } from './reducer';

const useColumns = (
    formatCurrencyDown,
    onCheckboxChange,
    isAllCheckboxSelected,
    viewApplicationDetails,
    hasFinanceProduct,
    hasBank,
    hasLoan,
    checkedIds
) => {
    const zone = useSelector(getCurrentZone);
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const columns = [
            {
                name: '',
                id: 'isSelected',
                renderCell: item =>
                    cells.renderCheckbox(onCheckboxChange)(
                        {
                            ...item,
                            isSelected: isAllCheckboxSelected || checkedIds?.includes(item.version.id),
                        },
                        currentItem => currentItem.version.id
                    ),
                showCheckbox: true,
                onCheckboxChange,
                isAllCheckboxSelected,
            },
            {
                name: 'Application Date',
                id: 'version.createdAt',
                renderCell: cells.renderDateTime('version.createdAt', formatDateTime),
                hasSort: true,
            },
            {
                name: 'App ID',
                id: 'identifier',
                renderCell: get('identifier'),
                underline: true,
                hasSort: true,
                onClick: viewApplicationDetails,
            },
            {
                name: 'Dealer',
                id: 'dealer.name',
                renderCell: get(formatPath('dealer.name')),
                hasSort: true,
            },
            {
                name: 'Salesperson',
                id: 'assignee.name',
                renderCell: get('assignee.name'),
                hasSort: true,
            },
            {
                name: 'Customer',
                id: 'customer.name.value',
                renderCell: get('customer.name.value'),
                hasSort: true,
            },
            {
                name: 'Variant',
                id: formatPath('variant.name'),
                renderCell: get(formatPath('variant.name')),
                hasSort: true,
            },
            hasBank && { name: 'Bank', id: 'bank.name', renderCell: get(formatPath('bank.name')), hasSort: true },
            hasFinanceProduct && {
                name: 'Financial Product',
                id: formatPath('financeProduct.name'),
                renderCell: get(formatPath('financeProduct.name')),
                hasSort: true,
            },
            hasLoan && {
                name: 'Loan',
                id: 'calculator.loan.amount',
                renderCell: cells.renderCurrencyDown('calculator.loan.amount', formatCurrencyDown),
                hasSort: true,
            },
            {
                name: 'Status',
                id: 'status',
                renderCell: cells.renderStatus(get('statusText')),
                hasSort: true,
            },
            {
                name: 'Transaction ID',
                id: 'transactionID',
                renderCell: get('transactionID'),
                hasSort: true,
            },
            {
                name: 'Last Activity',
                id: 'version.updatedAt',
                renderCell: cells.renderDateTime('version.updatedAt', formatDateTime),
                hasSort: true,
            },
            !zone && { name: 'Zone', id: 'zone.code', renderCell: get('zone.code'), hasSort: true },
        ];

        return columns.filter(Boolean);
    }, [
        onCheckboxChange,
        isAllCheckboxSelected,
        formatDateTime,
        viewApplicationDetails,
        formatPath,
        hasBank,
        hasFinanceProduct,
        hasLoan,
        formatCurrencyDown,
        zone,
        checkedIds,
    ]);
};

const ApplicationList = ({
    dealerIds,
    eventApplicationsDispatch: reducerDispatch,
    eventApplicationsState: reducerState,
}) => {
    const history = useHistory();
    const { formatCurrencyDown } = useCompanyFormatting();
    const location = useParams();

    const zoneId = useSelector(getZoneId);

    const { values } = useFormContext();
    const identifier = values.identifier || '';

    const { data } = useQuery(getEventApplicationsByZoneId, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: zoneId,
            filter: {
                dealerIds: dealerIds?.length ? dealerIds : [],
                eventIds: values?.id ? [values.id] : undefined,
                phase: ApplicationPhase.FINANCE,
            },
            paging: {
                limit: reducerState.paging.pageSize,
                offset: (reducerState.paging.page - 1) * reducerState.paging.pageSize,
            },
            sorting: [
                {
                    type: reducerState.sortedOn.type,
                    // @ts-ignore
                    order: getSortingOrderLabel(reducerState.sortedOn.order),
                },
            ],
        },
    });

    const { pagingProps, onSort, sortOnTable, onCheckboxChange } = useReducerActions(
        reducerState,
        reducerDispatch,
        data?.results?.count ?? 0
    );

    const applicationData = useMemo(() => {
        if (!data || !data?.results?.count) {
            return [];
        }

        return data?.results?.items.map(application => ({
            ...application,
            transactionID: getTransactionId(application), // hide variant for marketing reconsent
            variant:
                application.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT
                    ? null
                    : application.variant,
        }));
    }, [data]);

    const viewApplicationDetails = (event, item) => {
        history.push(`/workflow/applications/${item.version.id}`, { showCustomerSection: true });
    };

    const { hasBank, hasFinanceProduct, hasLoan } = useMemo(
        () => ({
            hasFinanceProduct: applicationData.some(application => application.financeProduct),
            hasBank: applicationData.some(application => application.bank.name),
            hasLoan: applicationData.some(application => application.calculator.loan),
        }),
        [applicationData]
    );

    const columns = useColumns(
        formatCurrencyDown,
        onCheckboxChange,
        reducerState.isCheckedAll,
        viewApplicationDetails,
        hasFinanceProduct,
        hasBank,
        hasLoan,
        reducerState.checkedIds
    );

    const [downloadModal, setDownloadModal] = useState(false);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        password => setDownloadModal(<Download onClose={closeDownloadModal} password={password} />),
        [setDownloadModal, closeDownloadModal]
    );

    const dispatch = useDispatch();

    const download = useCallback(
        () =>
            dispatch(
                exportEventsApplication(
                    location.id,
                    identifier,
                    reducerState.checkedIds,
                    ApplicationPhase.FINANCE,
                    dealerIds
                )
            ).then(password => {
                if (password) {
                    openDownloadModal(password);
                }
            }),
        [dealerIds, dispatch, identifier, location, openDownloadModal, reducerState.checkedIds]
    );

    if (!pagingProps.itemCount) {
        return null;
    }

    return (
        <ApvPortlet name="Finance Applications" closable open>
            <ScrollContainer>
                <ListTable
                    columns={columns}
                    items={applicationData}
                    onSort={onSort}
                    paging={pagingProps}
                    sortedOn={sortOnTable}
                />
                {pagingProps.pageCount && <Paging {...pagingProps} />}
            </ScrollContainer>
            <FootBar>
                <FootBarButton
                    disabled={reducerState.isCheckedAll ? false : reducerState.checkedIds.length === 0}
                    onClick={download}
                >
                    download
                </FootBarButton>
            </FootBar>
            {downloadModal}
        </ApvPortlet>
    );
};

ApplicationList.propTypes = {
    dealerIds: PropTypes.arrayOf(PropTypes.string),
    eventApplicationsDispatch: PropTypes.func,
    eventApplicationsState: PropTypes.shape({}),
};

export default withEventApplications(ApplicationList);
