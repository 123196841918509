import React from 'react';
import { FormSection } from 'redux-form';
import NumberUnit from './NumberUnit';

type ElectricConsumptionProps = {
    labelPrefix: string;
};

const ElectricConsumption = ({ labelPrefix }: ElectricConsumptionProps) => (
    <>
        <FormSection name="kilowatthoursPer100Kilometer">
            <NumberUnit labelPrefix={`${labelPrefix}/kilowatthoursPer100Kilometer`} />
        </FormSection>
        <FormSection name="watthoursPerKilometer">
            <NumberUnit labelPrefix={`${labelPrefix}/watthoursPerKilometer`} />
        </FormSection>
    </>
);

export default ElectricConsumption;
