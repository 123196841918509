import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { FootBarButton, FormTabContainer } from '../../../../../containers/Layout';
import { EventExternalSite } from '../../../../../schema';
import { getCurrentCountry, getGlobalPermissions } from '../../../../../selectors';
import ApvPortlet from '../../../../template/Portlet';
import { DealerSelectionDropDown } from '../../../../ui/lists';
import ApplicationList from './ApplicationList';
import ConsentList from './ConsentList';
import EDM from './EDM';
import Languages from './Languages';
import LeadList from './LeadList';
import MainDetails from './MainDetails';
import ReservationList from './ReservationList';
import Settings from './Settings';
import { Context } from './context';

const Form = props => {
    const { form, displayMultiLanguage } = props;
    const history = useHistory();
    const [dealerIds, setDealerIds] = useState(null);

    // get permissions
    const { mayViewConsentDeclaration, mayMangeConsentDeclaration } = useSelector(getGlobalPermissions);

    const values = useSelector(getFormValues(form));
    const { currency, rounding, channelSetting, code: countryCode } = useSelector(getCurrentCountry);

    const context = { ...props, values, currency, rounding, channelSetting, countryCode };

    const edition = !!values.lastModified;

    const addConsent = () => {
        history.push(`/consent/fromEvent/${values.id}`);
    };

    const isNotMarketingReconsent = values.setting.externalSite !== EventExternalSite.MARKETINGRECONSENT;

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && isNotMarketingReconsent && <Tab>Multi-Language</Tab>}
                    </TabList>
                    <TabPanel>
                        <ApvPortlet name="Main Details" closable open>
                            <MainDetails />
                        </ApvPortlet>
                        <ApvPortlet name="Settings" closable open>
                            <Settings />
                        </ApvPortlet>
                        {isNotMarketingReconsent && (
                            <ApvPortlet name="EDM" closable open>
                                <EDM />
                            </ApvPortlet>
                        )}
                        {edition && mayViewConsentDeclaration && (
                            <ApvPortlet name="Consents & Declarations" closable open>
                                <ConsentList />
                                {mayMangeConsentDeclaration && (
                                    <>
                                        <br />
                                        <FootBarButton onClick={addConsent}>
                                            <FontAwesomeIcon icon={faPlus} /> ADD CONSENT
                                        </FootBarButton>
                                    </>
                                )}
                            </ApvPortlet>
                        )}
                        {edition && <DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
                        {edition && <ReservationList dealerIds={dealerIds} />}
                        {edition && <ApplicationList dealerIds={dealerIds} />}
                        {edition && <LeadList dealerIds={dealerIds} />}
                    </TabPanel>
                    {displayMultiLanguage && isNotMarketingReconsent && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    displayMultiLanguage: PropTypes.bool,
    form: PropTypes.string.isRequired,
};

export default compose(reduxForm({ form: 'event' }), memo)(Form);
