import React from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../template/Field-container';
import InputField from '../../../template/Input';

type CurrencyProps = {
    labelPrefix: string;
};

const Currency = ({ labelPrefix }: CurrencyProps) => (
    <>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label={`${labelPrefix}/currencyCode`}>
                <Field component={InputField} name="currencyCode" disabled />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label={`${labelPrefix}/localize`}>
                <Field component={InputField} name="localize" disabled />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label={`${labelPrefix}/value`}>
                <Field component={InputField} name="value" disabled />
            </FieldContainer>
        </div>
    </>
);

export default Currency;
