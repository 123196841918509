import React from 'react';
import { FormSection } from 'redux-form';
import NumberUnit from './NumberUnit';

type FuelConsumptionProps = {
    labelPrefix: string;
};

const FuelConsumption = ({ labelPrefix }: FuelConsumptionProps) => (
    <>
        <FormSection name="kilometersPerLiter">
            <NumberUnit labelPrefix={`${labelPrefix}/kilometersPerLiter`} />
        </FormSection>
        <FormSection name="litersPer100Kilometer">
            <NumberUnit labelPrefix={`${labelPrefix}/litersPer100Kilometer`} />
        </FormSection>
        <FormSection name="milesPerGallonUK">
            <NumberUnit labelPrefix={`${labelPrefix}/milesPerGallonUK`} />
        </FormSection>
        <FormSection name="milesPerGallonUK">
            <NumberUnit labelPrefix={`${labelPrefix}/milesPerGallonUK`} />
        </FormSection>
    </>
);

export default FuelConsumption;
