import React from 'react';
import { FormSection } from 'redux-form';
import NumberUnit from './NumberUnit';

type EmissionProps = {
    labelPrefix: string;
};

const Emission = ({ labelPrefix }: EmissionProps) => (
    <FormSection name="gramsPerKilometer">
        <NumberUnit labelPrefix={`${labelPrefix}/gramsPerKilometer`} />
    </FormSection>
);

export default Emission;
